import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div id="TITILE">​IŠGĖRIAU</div>
          <img src="homer.png" id="randomidkodas" width='400' height='auto'  alt="bonka a"></img>
          <img src="taure_.png" id="rotatable-image" alt="bonka a"></img>
          <video id="main_video_player" src="https://cdn.mtdv.me/video/rick.mp4" loop="" preload="auto"></video>
      </header>
    </div>
  );
}

export default App;

